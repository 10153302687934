.containerPres{
    color:#FFF;
    background-color: #462D24 ;
    padding-top: 112px;
    padding-left: 120px;
    padding-bottom: 129px;
}


.presentation {
    display: flex;
    flex-direction: row;
    margin-top: 50px;
}

.underline {
    flex-grow: 1;
    border: 1px solid rgba(255, 255, 255, 0.1);
    margin-bottom: 10px;
}

.txtpresent{
    text-align: justify;
}

.img {
    width: 45vw;
    height: auto;
    margin-left: 122px;
}


@media screen and (max-width: 960px)  {
    .containerPres{
        padding-left: 80px;
        padding-right: 80px;
        padding-bottom: 80px;
    }
  

    .presentation {
        flex-direction: column;
        align-items: center;

    }

    .img {
        width: 100%;
        height: auto;
        margin-left: 0px;
        margin-top: 40px;
    }

    
}

@media screen and (max-width: 600px){

    .containerPres{
        padding: 30px;
    }

    .underline {
        display: none;
    }
    
}