

.container {
    padding-left: 120px;
	  padding-right: 120px;
    
}

.menu {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 150px;
    margin-bottom: 5em;
}

.contentproductcart {
    display:flex;
    justify-content: space-between;
    align-items: baseline;
}

.linePrice {
	flex: 1;
	border-bottom: 1px dashed grey;
}

.columnMenu {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	gap: 2rem;
	flex-wrap: wrap;
}

@media  screen and (max-width: 960px) {
  .container {
    padding-left: 80px;
    padding-right: 80px;
  }


}
@media screen and (max-width: 600px) {
    .container {
      padding-left: 30px;
      padding-right: 30px;
      margin-right: 0px;
      margin-left: 0px;
    }
  
    .menu {
      grid-template-columns: 1fr;
        grid-gap: 16px;
    }


  }