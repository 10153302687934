.hero {
  background: url('../../../assets/unsplash_sjBYA8dAw54.png') no-repeat center center;
  background-size: cover;
  height: 100vh;
  position: relative;
  
}

.hero::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(35, 35, 35, 0.5); /* Couleur noire avec une opacité de 50% */
  z-index: 1;
}

.heroContent {
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  color: white;
}

@media (max-width: 768px) {
  .heroTitle {
    font-size: 1.5rem; 
    text-align: center ;
  }


  button {
    margin-top: 5px; /* Ajuster l'espacement du bouton si nécessaire */
  }
}