

.info {
    display: flex;
    color: white;
    margin-bottom: 20px;
}

.policeInfo {
    font-family: 'Montserrat, sans-serif';
			font-size:  20px;
			font-weight: 400;
			line-height: 42px;
}
@media screen and (max-width: 960px) {

    .info {
        display: flex;
        color: white;
        margin-bottom: 20px;
        justify-content: space-between;
    }
   
  .policeInfo {

            font-size:  16px !important;

  }
    
  }