.map {
    width: 100%;
    height:   350px;
}

@media screen and (max-width: 960px) {
   
  
    .map {
        width: 100%;
        height: 250px;
    }
  
  
    
  }