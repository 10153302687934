.containerFoot{
  padding-top: 50px;
  padding-left: 120px;
  padding-right: 120px;
  background-color: #462D24;
}

.footerContent {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 20px;
  
}

@media screen and (max-width: 960px) {
  .containerFoot {
    padding-left: 80px;
    padding-right: 80px;

  }

  .footerContent {
    grid-template-columns: 1fr;
  }

  .reseaux {
   order : 3;
  }

}

@media  screen and (max-width: 600px) {
  .containerFoot {
    padding-left: 30px;
    padding-right: 30px;
  }

 
}
